@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Raleway');
/* @import url('https://fonts.googleapis.com/css?family=Alfa+Slab+One');
@import url('https://fonts.googleapis.com/css?family=Urbanist'); */

body {
  margin: 0;
  font-family: 'Josefin Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: 'Raleway',source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bigblue {
  color: DodgerBlue;
  padding: 40px;
  font-family: Arial;
  text-align: center;
} */